import { createTheme, ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import LogInView from "./components/LoginForm";
import HomePage from "./components/Pages/HomePage";
import ProjectsPage from "./components/Pages/ProjectsPage";
import RegisterView from "./components/RegisterForm";
import { routes } from "./routes";

import AboutPage from "./components/Pages/AboutPage";
import CalculationDesignPage from "./components/Pages/CalculationDesignPage";
import CalculationReportPage from "./components/Pages/CalculationReportPage";
import GlobalBasePage from "./components/Pages/GlobalBasePage";
import TemplateContentPage from "./components/Pages/TemplateContentPage";
import TemplateReportPage from "./components/Pages/TemplateReportPage";
import TemplateUploadPage from "./components/Pages/TemplateUploadPage";
import PublicCalcBase from "./components/public-calcs/common/PublicCalcBase";
import EfficientSteelBeamPage, {
  CALC_NAME as STEEL_BEAM_OPTIMIZER_NAME,
} from "./components/public-calcs/EfficientSteelBeamDesign";
import PublicCalcReportPage from "./components/public-calcs/PublicCalcReportPage";
import SteelBeamFlexurePage, {
  CALC_NAME as STEEL_BEAM_CALC_NAME,
} from "./components/public-calcs/SteelBeamFlexure";

export const GLOBAL_THEME = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#004aad",
      light: "#b3d4ff",
    },
    secondary: {
      main: "#faa92f",
      light: "#fccc83",
    },
    success: {
      main: "#00af54",
      light: "#d2ffc8",
    },
    error: {
      main: "#bf211e",
      light: "#ffc8cb",
    },
  },
  typography: {
    h1: {
      fontSize: "4rem",
      fontWeight: "500",
    },
    h2: {
      fontSize: "3rem",
      fontWeight: "600",
    },
    h3: {
      fontSize: "2.25rem",
      fontWeight: "600",
    },
    h4: {
      fontSize: "1.875rem",
      fontWeight: "600",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: "700",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: "700",
    },
    fontFamily: ["Sarabun", "Roboto", "Helvetica Neue", "Arial", "sans-serif"].join(","),
  },
});

// TODO: add captcha to contact form?
// TODO: add email verification/confirmation
// TODO: update error page
const router = createBrowserRouter([
  {
    path: routes.home.path,
    element: <GlobalBasePage />,
    errorElement: <div>Sorry, this page doesn't seem to exist.</div>,
    children: [
      {
        errorElement: <div>Sorry, this page doesn't seem to exist.</div>,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: routes.about.path,
            element: <AboutPage />,
          },
          {
            path: routes.login.path,
            element: <LogInView />,
          },
          {
            path: routes.register.path,
            element: <RegisterView />,
          },
          {
            path: routes.projects.path,
            element: <ProjectsPage />,
          },
          {
            path: routes.templates.path,
            element: <TemplateUploadPage />,
          },
          {
            path: routes.templateContent.path(),
            element: <TemplateContentPage />,
          },
          {
            path: routes.calculation.path(),
            element: <CalculationDesignPage />,
          },
          {
            path: "public-calc",
            element: <PublicCalcBase />,
            children: [
              {
                path: routes.publicCalculation.path(STEEL_BEAM_CALC_NAME),
                element: <SteelBeamFlexurePage />,
              },
              {
                path: routes.publicCalculation.path(STEEL_BEAM_OPTIMIZER_NAME),
                element: <EfficientSteelBeamPage />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: routes.calculationReport.path(),
    element: <CalculationReportPage />,
    errorElement: <div>Sorry, this page doesn't seem to exist.</div>,
  },
  {
    path: routes.templateReport.path(),
    element: <TemplateReportPage />,
    errorElement: <div>Sorry, this page doesn't seem to exist.</div>,
  },
  {
    path: routes.publicCalculationReport.path(),
    element: <PublicCalcReportPage />,
    errorElement: <div>Sorry, this page doesn't seem to exist.</div>,
  },
]);

function App() {
  return (
    <ThemeProvider theme={GLOBAL_THEME}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
