import { Alert, Box, Button, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { PublicCalcName } from "../../../commonTypes/CalculationT";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getUser } from "../../../reduxSlices/auth";
import { errorsActions } from "../../../reduxSlices/errors";
import { useCreateTemplateFromPublicCalcMutation } from "../../../reduxSlices/template";
import { routes } from "../../../routes";
import CodeContents from "./CodeContents";
import { P, Subtitle, Title } from "./copy-utils";

type Props = {
  calcName: PublicCalcName;
};

export default function CloneOrCopyCode({ calcName }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const [createTemplate, { isLoading }] = useCreateTemplateFromPublicCalcMutation();

  const saveTemplate = async () => {
    const result = await createTemplate(calcName);

    if ("data" in result && result.data.id) {
      navigate(routes.templateContent.path(result.data.id));
    } else {
      dispatch(
        errorsActions.throwError(
          "There was an error saving this template to your account. Please try again or reach out using the feedback button for help."
        )
      );
    }
  };

  return (
    <Container maxWidth="md" sx={{ marginBlock: 4 }}>
      <Title variant="h2" gutterBottom>
        Save or copy this template:
      </Title>
      <P>Edit this calculation template now by cloning it to your saved templates:</P>
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <Button variant="contained" disabled={!user || isLoading} onClick={saveTemplate}>
          Save this to my templates
        </Button>
        {!user && (
          <Alert severity="info" sx={{ marginBlock: "1rem" }}>
            Please{" "}
            <Link to={routes.login.path}>
              <b>login</b>
            </Link>{" "}
            or{" "}
            <Link to={routes.register.path}>
              <b>create a free account</b>
            </Link>{" "}
            so you can save, edit, and create your own calculations.
          </Alert>
        )}
      </Box>
      <P>
        You get this same interactive design form as well as free calculation reports for all of
        your online efficalc calculations.
      </P>
      <Subtitle variant="h2" gutterBottom>
        Copy the efficalc code
      </Subtitle>
      <P>
        Instead of cloning to your online efficalc templates, you can copy the calculation code
        below and run it with the open source efficalc library!
      </P>
      <CodeContents calcName={calcName} />
      <P>
        Note: you can make suggestions for improvements to this code at{" "}
        <a
          href="https://github.com/youandvern/efficalc/issues"
          target="_blank"
          rel="noopener noreferrer"
        >
          github.com/youandvern/efficalc/issues
        </a>{" "}
        or by sending us a message with the contact button.
      </P>
    </Container>
  );
}
