import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Collapse, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../../routes";
import { StyledNavLink } from "./NavComponents";

const ToolsMenuItem = ({
  path,
  navName,
  handleCloseAll,
  formatForPopupMenu,
}: {
  path: string;
  navName: string;
  handleCloseAll: () => void;
  formatForPopupMenu: boolean;
}) => (
  <StyledNavLink to={path} fullWidth={formatForPopupMenu}>
    {({ isActive }) => (
      <MenuItem onClick={handleCloseAll}>
        <Typography
          color={(theme) => (isActive ? theme.palette.secondary.main : theme.palette.primary.main)}
        >
          {navName}
        </Typography>
      </MenuItem>
    )}
  </StyledNavLink>
);

type Props = {
  formatForPopupMenu: boolean;
  handleCloseIconMenu: () => void;
};

export default function ToolsMenu({ formatForPopupMenu, handleCloseIconMenu }: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const currentPath = useLocation().pathname;

  const isCurrentPathToolsPath = currentPath.includes("public-calc/");

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseAll = () => {
    handleCloseIconMenu();
    setOpen(false);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const toolsMenuList = (
    <MenuList
      autoFocusItem={open}
      id="composition-menu"
      aria-labelledby="composition-button"
      onKeyDown={handleListKeyDown}
    >
      <ToolsMenuItem
        path={routes.publicCalculation.path("steel-beam-optimizer")}
        navName="Steel Beam Optimizer"
        {...{ handleCloseAll, formatForPopupMenu }}
      />
      <ToolsMenuItem
        path={routes.publicCalculation.path("steel-beam-calculator")}
        navName="Steel Beam Designer"
        {...{ handleCloseAll, formatForPopupMenu }}
      />
    </MenuList>
  );

  return (
    <div>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              rotate: open ? "180deg" : "0deg",
              transition: (theme) => theme.transitions.create("rotate"),
            }}
          />
        }
        sx={{
          my: 2,
          fontWeight: "bold",
          color: (theme) =>
            isCurrentPathToolsPath
              ? theme.palette.secondary.main
              : formatForPopupMenu
              ? theme.palette.primary.main
              : "white",
          margin: formatForPopupMenu ? "0" : "0.5rem",
          textAlign: formatForPopupMenu ? "left" : "center",
        }}
      >
        Tools
      </Button>
      {formatForPopupMenu ? (
        <Collapse orientation="vertical" in={open}>
          {toolsMenuList}
        </Collapse>
      ) : (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          sx={{ position: formatForPopupMenu ? "relative" : "absolute" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseAll}>{toolsMenuList}</ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  );
}
