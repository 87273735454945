import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

type Props = {
  data: (string | number)[][];
  setData: (newData: (string | number)[][]) => void;
  headers?: string[];
  title?: string;
};

export default function InputTableItem({ data, setData, headers, title }: Props) {
  const tableName = title ? `: ${title}` : "";

  const onChangeCell = (rowNum: number, colNum: number, newVal: string) => {
    setData(
      data.map((row, rowId) =>
        row.map((cell, colId) => (rowId === rowNum && colId === colNum ? newVal : cell))
      )
    );
  };

  const onDeleteRow = (rowNum: number) => {
    setData(data.filter((r, idx) => rowNum !== idx));
  };

  const lastRowLen = data[data.length - 1].length;

  const onAddRow = () => {
    const newRow = new Array(lastRowLen).fill("");
    setData([...data, newRow]);
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <b>Input table{tableName}</b>
        </AccordionSummary>
        <AccordionDetails>
          <Table size="small">
            {headers && (
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {headers.map((h, idx) => (
                    <TableCell>
                      <Typography fontWeight="bold" key={idx}>
                        {h}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {data.map((row, rid) => (
                <TableRow key={rid}>
                  <TableCell>
                    <IconButton onClick={() => onDeleteRow(rid)} aria-label="delete row">
                      <RemoveCircleIcon />
                    </IconButton>
                  </TableCell>
                  {row.map((cell, cid) => (
                    <TableCell key={`cell-{$cid}`} sx={{ paddingInline: 1 }}>
                      <OutlinedInput
                        key={`cell-{$cid}`}
                        value={cell}
                        onChange={(e) => onChangeCell(rid, cid, e.target.value)}
                        margin="none"
                        size="small"
                        fullWidth
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={lastRowLen + 1}>
                  <Button fullWidth onClick={onAddRow}>
                    <AddCircleIcon /> New Row
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
