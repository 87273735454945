import { Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateMathJax } from "../..";
import {
  CalcTable,
  CalcTypeToParse,
  Canvas,
  FigureBase,
  InputVariable,
} from "../../commonTypes/CalculationRunTypes";
import { CalculationRunDto } from "../../commonTypes/CalculationT";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  calculationActions,
  getCalculationRunResults,
  getCalculationRunStatus,
  getCurrentCalculation,
} from "../../reduxSlices/calculation";
import { routes } from "../../routes";
import CalculationInputTable, { FormValuesT, TableFormValuesT } from "../CalculationInputTable";
import CalculationResultsView from "../CalculationResultsView";
import CanvasDesigner from "../CanvasDesigner";
import FigureDesigner from "../FigureDesigner";
import FormPendingSkeleton from "../FormPendingSkeleton";
import ResultActionButtons from "../ResultActionButtons";

// TODO: Improve loading skeleton
export default function CalculationDesignPage() {
  const dispatch = useAppDispatch();
  const currentCalculationInfo = useAppSelector(getCurrentCalculation);
  const currentRun = useAppSelector(getCalculationRunResults);
  const runStatus = useAppSelector(getCalculationRunStatus);
  const calcId = +(useParams().id ?? NaN);

  const [inputItems, setInputItems] = useState<InputVariable[]>([]);
  const [inputTables, setInputTables] = useState<CalcTable[]>([]);
  const [inputFigures, setInputFigures] = useState<JSX.Element[]>([]);
  const [resultItems, setResultItems] = useState<CalcTypeToParse[]>([]);
  const [inputs, setInputs] = useState<FormValuesT>({});
  const [inputChanged, setInputChanged] = useState(false);

  const updateResults = () => {
    const tableInputs: TableFormValuesT = inputTables.reduce<TableFormValuesT>(
      (prev, cur) => ({ ...prev, [cur.name ?? "input-table"]: cur.data || [[]] }),
      {}
    );
    dispatch(
      calculationActions.runCalculation({
        id: calcId,
        inputs: { ...inputs, ...tableInputs },
      } as CalculationRunDto)
    );
    setInputChanged(false);
  };

  useEffect(() => {
    document.title = "Design Portal | efficalc";
  }, []);

  // update mathjax whenever math containing items change
  useEffect(() => {
    updateMathJax();
  }, [inputItems, resultItems]);

  useEffect(() => {
    if (!!calcId) {
      if (calcId !== currentCalculationInfo?.id)
        dispatch(calculationActions.fetchCalculation(calcId));

      dispatch(calculationActions.getCalculationItems(calcId));
    }
  }, [calcId]);

  useEffect(() => {
    const inputItemList: InputVariable[] = [];
    const inputTableList: CalcTable[] = [];
    const inputFigureList: JSX.Element[] = [];
    const resultItemList: CalcTypeToParse[] = [];

    currentRun?.items.forEach((item, index) => {
      switch (item.type) {
        case "Input":
          inputItemList.push(item as InputVariable);
          break;
        case "InputTable":
          inputTableList.push(item as CalcTable);
          break;
        case "Canvas":
          const canvas = item as Canvas;
          if (canvas.displayType === "report-input")
            inputFigureList.push(<CanvasDesigner item={canvas} key={index} />);
          if (canvas.displayType === "report-result") resultItemList.push(item);
          break;
        case "FigureBase":
          const figure = item as FigureBase;
          if (figure.displayType === "report-input")
            inputFigureList.push(<FigureDesigner item={figure} key={index} />);
          if (figure.displayType === "report-input") resultItemList.push(item);
          break;
        case "Calculation":
        case "Comparison":
        case "ComparisonForced":
        case "Symbolic":
        case "Table":
          if (!!item.finalResult) resultItemList.push(item);
          break;
        default:
          break;
      }
    });
    setInputItems(inputItemList);
    setInputTables(inputTableList);
    setInputFigures(inputFigureList);
    setResultItems(resultItemList);
  }, [currentRun]);

  const resultActionButtons = (
    <ResultActionButtons
      pathToReportPage={routes.calculationReport.path(currentRun?.id || calcId || 0)}
      updateButtonText="Save inputs and refresh results."
      updateResults={updateResults}
      isResultStale={inputChanged}
      isRunLoading={runStatus === "loading" || false}
      runResults={currentRun?.items}
    />
  );

  return (
    <Container maxWidth="xl">
      <Stack marginY="1rem">
        <Typography variant="h2" align="center" gutterBottom>
          {currentCalculationInfo?.name}
        </Typography>
        {calcId !== currentRun?.id ? (
          <FormPendingSkeleton />
        ) : (
          <Stack direction={{ xs: "column", md: "row" }} spacing={4} justifyContent="center">
            <CalculationInputTable
              inputItems={inputItems}
              onUpdateInputVals={() => setInputChanged(true)}
              updatedInputState={[inputs, setInputs]}
              inputTableState={[inputTables, setInputTables]}
              inputFigures={inputFigures}
            />
            <CalculationResultsView
              resultActionButtons={resultActionButtons}
              resultItems={resultItems}
              isResultStale={inputChanged}
            />
          </Stack>
        )}
      </Stack>
    </Container>
  );
}
