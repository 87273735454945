import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { errorsActions } from "../../reduxSlices/errors";
import { getTemplateRunResults, templatesActions } from "../../reduxSlices/template";
import CalcReport from "../CalcReport";
import ErrorManager from "../ErrorManager";
import ErrorSnackbar from "../ErrorSnackbar";
import FormPendingSkeleton from "../FormPendingSkeleton";

function HeadTags() {
  return (
    <Helmet>
      <title>Calculation Report | efficalc</title>
      <meta property="og:title" content="Calculation Reports" />
      <meta name="description" content="View or print your calculation report." />
      <meta property="og:description" content="View or print your calculation report." />
      <meta property="og:image" content="https://efficalc.com/efficalcOG.png" />
    </Helmet>
  );
}

export default function TemplateReportPage() {
  const dispatch = useAppDispatch();
  const currentTemplateRun = useAppSelector(getTemplateRunResults);
  const templateId = +(useParams().id ?? NaN);

  // Fetch all required data if not already in store i.e. direct page load
  useEffect(() => {
    if (!!templateId) {
      dispatch(templatesActions.runTemplate(templateId));
      dispatch(templatesActions.setCurrentTemplate(templateId));
    } else {
      dispatch(errorsActions.throwError("Invalid template identifier."));
    }
  }, [templateId]);

  return (
    <>
      <HeadTags />
      <ErrorManager />
      <ErrorSnackbar />
      {templateId !== +(currentTemplateRun?.id ?? NaN) ? (
        <FormPendingSkeleton />
      ) : (
        <CalcReport runResults={currentTemplateRun?.items || []} />
      )}
    </>
  );
}
