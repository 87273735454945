import { PublicCalcName } from "../../commonTypes/CalculationT";
import { FormValuesT } from "../CalculationInputTable";

export const saveLocalInputs = (calcName: PublicCalcName, inputs: FormValuesT): void => {
  localStorage.setItem(calcName, JSON.stringify(inputs));
};

export const getLocalInputs = (calcName: PublicCalcName): FormValuesT => {
  const savedItems = localStorage.getItem(calcName);

  if (savedItems) {
    return JSON.parse(savedItems) as FormValuesT;
  } else {
    return {};
  }
};
